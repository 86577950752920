import styled from "styled-components";
import BaseButton from './BaseButon';

export const ReadMoreButton = styled(BaseButton)`
  &,
  &:visited,
  &:active,
  &:focus {
    text-transform: none;
    padding: .6rem 1rem;
    background-color: #990702;
    color: #fff !important;
    border-radius: 4px;
    font-size: 1.4rem;
  }
`;