import styled from 'styled-components';
import { Link } from "gatsby";
import { Container } from "../styles/Container";

export const NewsItemsListContainer = styled.div`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 44rem));
  justify-content: center;
  grid-column-gap: 3.5rem;
  grid-row-gap: 5rem;
  grid-auto-flow: row dense;
`;

export const NewsContainer = styled(Container)``;

const BaseNewsItemCard = styled.div`
  border: 1px solid #414c52;

  .title {
    font-weight: 600;
    margin-bottom: .5rem;
    flex: 0 0 5.4rem;
  }
  .action {
    margin-top: auto;
    display: flex;
    align-items: center;
    a {
      margin-right: 1.5rem;
    }
  }
  .excerpt {
    color: #5a5a5a;
    padding-bottom: 2rem;
  }
`;

export const StyledNewsCardDate = styled.div`
  display: flex;
  align-items: center;
  color: #7a7a7a;
  margin-bottom: 1.5rem;

  svg {
    margin-right: .8rem;
  }
`;

export const StyledNewsItemCard = styled(BaseNewsItemCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  :first-of-type {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
`;

export const StyledNewsItemCardWithImage = styled(BaseNewsItemCard)`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 32rem 1fr;
  grid-column-gap: 1.5rem;
  justify-content: start;
  .content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  @media (max-width: 1024px) {
    grid-column: span 1;
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

export const NewsNavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const NewsNavigationButton = styled(Link)`
  background-color: #f0f9ff;
  padding: 1.5rem 2.5rem;
  display: flex;
  align-items: center;
`;