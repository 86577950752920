import React from 'react';

import { NewsItemsListContainer } from './styles';
import NewsItemCard from './NewsItemCard';
import NewsItemCardWithImage from './NewsItemCardWithImage';

const NewsItemsList = ({ items }) => (
  <NewsItemsListContainer>
    {items.map(({ title, date, slug, image, excerpt }) => image ? (
      <NewsItemCardWithImage
        key={slug}
        title={title}
        excerpt={excerpt}
        date={date}
        url={slug}
        image={image}
      />
    ) : (
      <NewsItemCard
        key={slug}
        title={title}
        excerpt={excerpt}
        date={date}
        url={slug}
      />
    ))}
  </NewsItemsListContainer>
)

export default NewsItemsList