import React from "react";

import { StyledNewsItemCard } from "./styles";
import { showDateString } from "../../features/Helpers/formatDate";
import { ReadMoreButton } from "../UI/Buttons/ReadMoreButton";
import NewsCardDate from "./NewsCardDate";

const NewsItemCard = ({ title, excerpt, date, url }) => {
  return (
    <StyledNewsItemCard>
      <div className="title">{ title }</div>
      <div className="excerpt" dangerouslySetInnerHTML={ { __html: excerpt } } />
      <div className="action">
        <ReadMoreButton to={ `/novosti/${url}` }>Читать</ReadMoreButton>
        <NewsCardDate date={ showDateString(date) } />
      </div>
    </StyledNewsItemCard>
  );
};

export default NewsItemCard;