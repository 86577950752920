import React from 'react';
import Img from "gatsby-image";

import { showDateString } from "../../features/Helpers/formatDate";

import { StyledNewsItemCardWithImage } from './styles';
import { ReadMoreButton } from "../UI/Buttons/ReadMoreButton";
import NewsCardDate from "./NewsCardDate";


const NewsItemCardWithImage = ({ title, image, excerpt, date, url }) => (
  <StyledNewsItemCardWithImage>
    <Img className="image" fluid={image.fluid} />
    <div className="content">
      <div className="title">{ title }</div>
      <div className="excerpt">{ excerpt }</div>
      <div className="action">
        <ReadMoreButton to={ `/novosti/${url}` }>Читать</ReadMoreButton>
        <NewsCardDate date={ showDateString(date) } />
      </div>
    </div>
  </StyledNewsItemCardWithImage>
)

export default NewsItemCardWithImage