import {Link} from "gatsby"
import styled from "styled-components";

const BaseButton = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  display: inline-block;
`;

export default BaseButton;