import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { NewsContainer } from "../components/News/styles";
import NewsItemsList from "../components/News/NewsItemsList";
import NewsNavigation from "../components/News/NewsNavigation";

import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
const breadcrumbsFactory = new BreadcrumbsFactory();

const News = ({ pageContext }) => {
  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: 'novosti',
      title: 'Новости',
    },
  ]);

  return (
    <Layout crumbs={crumbs}>
      <SEO title="Новости" />
      <NewsContainer className="container">
        <h1>Новости</h1>
        <NewsItemsList items={pageContext.items} />
        <NewsNavigation
          next={pageContext.next}
          prev={pageContext.prev}
        />
      </NewsContainer>
    </Layout>
  )
}

export default News;
