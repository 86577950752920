import React from 'react';

import { StyledNewsCardDate } from './styles';
import IconCalendar from '../../assets/icons/calendar.svg';

const NewsCardDate = ({ date }) => (
  <StyledNewsCardDate>
    <IconCalendar height="16" width="16" fill="#7a7a7a" />
    <span>{ date }</span>
  </StyledNewsCardDate>
);

export default NewsCardDate