import React from 'react';

import { NewsNavigationContainer, NewsNavigationButton } from './styles';
import IconArrow from "../../assets/icons/left-arrow.svg";

const NewsNavigation = ({ next, prev }) => (
  <NewsNavigationContainer>
    <div className="previous">
      { Boolean(prev) && (
        <NewsNavigationButton to={prev}>
          <IconArrow
            width="24"
            height="24"
            style={{
                marginRight: "1rem",
            }}
            />
          Назад
        </NewsNavigationButton>
      )}
    </div>
    <div className="next">
      { Boolean(next) && (
        <NewsNavigationButton to={next}>
          Дальше
          <IconArrow
            width="24"
            height="24"
            style={{
              transform: "rotate(180deg)",
              marginLeft: "1rem",
            }}
          />
        </NewsNavigationButton>
      )}
    </div>
  </NewsNavigationContainer>
)

export default NewsNavigation